.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper {
  height: 35px;
}

.otp-inputs:not(:first-child) {
  margin-left: 20px;
}
.otp-inputs > input {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #c4da00;
  border-radius: 10%;
}
.otp-inputs > input:focus {
  /* border: 0 !important; */
  outline: none;
}

.containerss {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.7fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}

@media only screen and (max-width: 978px) {
  .containerss {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
}

.modal-header {
  border-bottom: none !important;
}

.mdd {
  /* background-color: green; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.midss {
  color: #fff;
  text-align: center;
  /* width: 100%; */
  /* height: 100vh; */
  display: grid;
  justify-content: center;
  align-items: center;
}

.form-control-lg {
  font-size: 1rem !important;
}

.distance {
  height: calc(100vh - 30px - 85vh);
}

.distance p {
  font-weight: bold;
}

.mapContainer {
  height: calc(100vh - 30px);
  overflow-y: hidden;
}

.parentDiv {
  position: relative; /* Ensure the parent is relatively positioned */
  height: calc(
    100vh - 70px
  ); /* Set the height of the parent div (adjust as needed)
  /* border: 1px solid #ccc; */
}

.childDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0; /* Optional: Add background color for visibility */
  padding: 5px; /* Optional: Add padding to the child div */
}

.dropdown {
  position: absolute;
  /* top: 10%; */
  /* Position it relative to the parent */
  max-height: 200px;
  /* Maximum height before it becomes scrollable */
  overflow-y: auto;
  /* Make it scrollable */
  width: 50%;
  /* Match the width of the input field */
  z-index: 1000;
  /* Ensure it's on top of other elements */
  background: white;
  /* Background color for visibility */
  border: 1px solid #ccc;
  /* Optional border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional shadow for better visibility */
}

.category-selector {
  position: relative;
  /* Other styles... */
}