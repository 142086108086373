main {
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  padding: 20px 35px;
}

.main__title {
  display: flex;
  align-items: center;
}

.separatingDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 20px 0;
}

.main__cards {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 30px;
  margin: 0 0 20px 0;
}

.cardsz {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
}

.cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
}

.cardss {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
}

.card_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_inner > span {
  font-size: 25px;
}

.cards_img {
  width: 60px;
}

.cards_img img {
  width: 100%;
}

.card_inners_p {
  font-size: 16px;
  font-weight: 400;
}

.card_inners_amount {
  font-weight: 600 !important;
  font-size: 26px !important;
}

.requests {
  display: grid;
  grid-template-columns: 2fr;
  margin-bottom: 30px;
}

.requestFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropOff__title {
  font-size: 15px;
  /* margin-left: 10px; */
}

.dropOff_image {
  width: 60px;
  /* height: 80px; */
}

.dropOff_image img {
  width: 100%;
  /* height: 100%; */
}

.reqBg {
  background: #f8fff8;
  padding: 10px 5px;
}

.buttonLike {
  width: 25px;
  height: 25px;
  background-color: #c4da00;
  border-radius: 5px;
  display: flex;
  /* align-items: center; */
}

.mapImage {
  width: max-content;
  height: fit-content;
  /* display: flex; */
}

.mapImage img {
  width: 100%;
}

.def {
  padding: 8px 32px;
  border-radius: 5px;
  outline: none;
  border: none;
  background: transparent;
}

.def:focus {
  outline: none;
  border: none;
}

.styled {
  background-color: #c4da00;
}

.buttonHolder {
  padding: 10px;
  background-color: #f8fff8;
  border-radius: 5px;
}

.box {
  width: 10px;
  height: 10px;
  border-radius: 1px;
}

.green {
  background-color: #c4da00;
}

.dark {
  background-color: #404040;
}

.thisMonth {
  width: fit-content;
  padding: 10px 15px;
  background-color: #f8fff8;
}
