.cardz {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  /* border: 1px solid red; */
  /* box-shadow: 5px 5px 13px #ededed, -5px 13px #fff; */
}
