.rightImg {
  background-image: url("../../../../assets/images/backgroundWall.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.headerText {
  font-size: 30px;
  color: #231f20;
  font-weight: 500;
  margin-bottom: 13px;
}

.loginStyle {
  font-size: 20px;
  color: #c4da00;
  margin-bottom: 15px !important;
}

.pin {
    font-size: 13px;
    margin-bottom: 25px;
}

.loginBtn {
  background-color: #c4da00;
  font-weight: bold;
  box-shadow: none !important;
  height: 40px;
}

.loginBtn:focus {
  outline: none;
  box-shadow: none;
}

.validateOtp {
  margin-top: 50px;
}
