tr.ant-table-row {
  background: #f8fff8;
}
form {
  accent-color: #dbe864;
}
.ant-table-tbody > tr > td {
  border-bottom: 6px solid #fff !important;
}

.cards4 {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
}

.main__cards4 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin: 0 0 20px 0;
}

.w33 {
  width: 32%;
}

.gen {
  margin-top: 7px;
  height: 35px !important;
}

.reqBg {
  background: #f8fff8 !important;
  padding: 10px 5px !important;
}

.w-60 {
    width: 60%;
}
