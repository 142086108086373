tr.ant-table-row {
  background: #f8fff8;
}
form {
  accent-color: #dbe864;
  
}
.ant-table-tbody > tr > td {
  border-bottom: 6px solid #fff !important;
}
