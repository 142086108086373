.rightImg {
  background-image: url("../../../../assets/images/backgroundWall.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.headerText {
  font-size: 30px;
  color: #231F20;
  font-weight: 500;
  margin-bottom: 13px;
}

.loginStyle {
  font-size: 20px;
  color: #c4da00;
  margin-bottom: 15px !important;
}

.loginBtn {
  background-color: #c4da00;
  font-weight: bold;
  box-shadow: none !important;
  height: 40px;
}

.loginBtn:focus {
  outline: none;
  box-shadow: none;
}

.labelStyle {
  color: #4A4A4A;
  font-size: 13px;
  margin-bottom: 3px !important;
}

input[type="email"] {
  font-size: 13px;
}

input::-webkit-input-placeholder, textarea::placeholder, input[type="date"]::placeholder {
  color: #231F20;
  /* font-family: "poppins"; */
  font-size: 13px;
}

.forgotPassword {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.fpw {
  cursor: pointer;
}

.spanStyle {
  color: #c4da00;
}