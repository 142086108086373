input[type="text"] {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.form-label {
  font-size: 13px;
}

.imgAvatarHolder {
  width: 50px;
}

.imgAvatarHolder img {
  width: 100%;
}

.fontBold {
  font-weight: 600;
}

.successImg {
  width: 200px;
  margin: auto;
  text-align: center;
}

.successImg img {
  width: 100%;
}

.dropdown {
  position: absolute;
  /* top: 10%; */
  /* Position it relative to the parent */
  max-height: 200px;
  /* Maximum height before it becomes scrollable */
  overflow-y: auto;
  /* Make it scrollable */
  width: 50%;
  /* Match the width of the input field */
  z-index: 1000;
  /* Ensure it's on top of other elements */
  background: white;
  /* Background color for visibility */
  border: 1px solid #ccc;
  /* Optional border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional shadow for better visibility */
}

.category-selector {
  position: relative;
  /* Other styles... */
}