.successful {
  background-color: #c4da00;
  color: #000;
  font-size: 13px;
  width: max-content;
  padding: 5px 10px;
  border-radius: 50px;
}

.failed {
  background-color: #eb5757;
  color: #fff;
  font-size: 13px;
  width: max-content;
  padding: 5px 10px;
  border-radius: 50px;
}
