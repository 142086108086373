main {
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  padding: 20px 35px;
}

.separatingDivs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin: 20px 0;
}

.main__cardss {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 0 0 20px 0;
}

.cards_extra {
  justify-content: center;
  text-align: center;
}
.cardsss {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
}

.card_inners_p {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.requestss {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 30px;
}

.req__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 0 0 20px 0;
}

.unreg {
  font-size: 14px !important;
  width: 90%;
}
form.flex__list {
  accent-color: #cbde1f;
}
