* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pn-msg-list {
  background: #fff !important;
}

@media only screen and (min-width: 700px) and (max-width: 1028px) {
  .main__cardx {
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 10px !important;
    margin: 0 0 10px 0 !important;
  }

  .sidebar_responsive {
    box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
  }

  .fullDiv {
    grid-template-columns: 1fr !important;
    gap: 30px;
    margin: 20px 0;
  }

  .w33,
  .makeFull,
  .auth {
    width: 100% !important;
  }

  .applyMl {
    margin-left: 10px !important;
  }

  .newW {
    width: 100% !important;
  }

  .separatingDiv {
    grid-template-columns: 1fr !important;
    gap: 30px;
    margin: 20px 0;
  }

  .mobileMapp {
    width: 80vw !important;
  }

  .mobileMapps {
    width: 91vw !important;
  }

  .mapImage {
    margin-left: 0 !important;
  }

  .nav_icon {
    display: inline !important;
  }

  .adjW {
    width: 50% !important;
  }
}

@media only screen and (max-width: 500px) {
  .cardss {
    min-width: -webkit-fill-available;
  }
  .sidebar_responsive {
    box-shadow: 5px 5px 13px #ededed, -5px 13px #fff;
  }

  .rightImg {
    display: none;
  }

  .containerWidth {
    width: 95% !important;
    margin-left: 10px !important;
  }

  .PaddingLeft-10 {
    padding-left: 10px !important;
  }

  .noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
  }

  .headerh5 {
    font-size: 28px !important;
  }

  .newH5 {
    font-size: 24px !important;
  }

  .buttons {
    display: block !important;
  }

  .mtop {
    margin-top: 20px !important;
  }

  /* .navbar {
    display: none !important;
  } */

  .main__container {
    padding: 10px 15px !important;
  }

  .separatingDiv {
    grid-template-columns: 1fr !important;
    gap: 30px;
    margin: 20px 0;
  }

  .main__cards {
    grid-template-columns: 1fr !important;
    gap: 10px !important;
    margin: 0 0 10px 0 !important;
  }

  .requests {
    grid-template-columns: 1fr !important;
  }

  .chartImg {
    width: 300px;
  }

  .chartImg img {
    width: 100%;
  }

  .mobileMapp {
    width: 80vw !important;
  }

  .mobileMapps {
    width: 91vw !important;
  }

  .mobileMappss {
    width: 100vw !important;
  }

  .mapImage {
    margin-left: 0 !important;
  }

  .dropOff_image {
    width: 50px !important;
  }

  .dropOff__title {
    margin-left: 0 !important;
    margin-top: 0.5rem !important;
  }

  .card_inners_p {
    margin-bottom: 9px !important;
  }

  .nav_icon {
    display: inline !important;
  }

  .main__cardss,
  .req__grid {
    grid-template-columns: 0.99fr !important;
  }

  .blockWay {
    display: block !important;
  }

  .spaceTop {
    margin-top: 20px !important;
  }

  .addShow {
    display: none !important;
  }

  .newW {
    width: 100% !important;
  }

  .adjW {
    width: 50% !important;
  }

  .adjW2 {
    width: 65% !important;
  }

  .w33,
  .makeFull {
    width: 100% !important;
  }

  .ant-table-wrapper {
    overflow-x: auto !important;
  }
  .btnBottomDiv {
    position: absolute;
    top: 80vh;
    width: 83%;
  }

  .btnBottomDivs {
    position: absolute;
    top: 75vh;
    width: 83%;
  }

  .greenBtn {
    background-color: #c4da00;
    font-weight: bold;
    box-shadow: none !important;
    height: 40px;
  }

  .whiteBtn {
    background-color: #fff;
    border: 1px solid #c4da00;
    font-weight: bold;
    box-shadow: none !important;
    height: 40px;
  }

  .btnBottomDivss {
    position: absolute;
    top: 75vh;
    width: 95%;
  }
}
