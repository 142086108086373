.rightImg {
  background-image: url("../../../../assets/images/backgroundWall.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
}

.headerText {
  font-size: 30px;
  color: #231f20;
  font-weight: 500;
  margin-bottom: 13px;
}

.loginStyle {
  font-size: 20px;
  color: #c4da00;
  margin-bottom: 15px !important;
}

.passwordBtn {
  background-color: #c4da00;
  font-weight: bold;
  box-shadow: none !important;
  height: 40px;
}

.passwordBtn:focus {
  outline: none;
  box-shadow: none;
}

.labelStyle {
  color: #4a4a4a;
  font-size: 13px;
  margin-bottom: 3px !important;
}

input[type="email"] {
  font-size: 13px;
}

input::-webkit-input-placeholder {
  color: #231F20;
  /* font-family: "poppins"; */
  font-size: 13px;
}
