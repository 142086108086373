.wrapper {
  padding: 20px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.notificationText {
  font-size: 12px;
}

.notificaationDate {
  font-size: 12px;
  color: #898989;
}

.notificationAmount {
  font-weight: bold;
  font-size: 13px;
}
