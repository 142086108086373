input[type="text"] {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.form-label {
  font-size: 13px;
}

.imgAvatarHolder {
  width: 50px;
}

.imgAvatarHolder img {
  width: 100%;
}

.fontBold {
  font-weight: 600;
}

.successImg {
  width: 200px;
  margin: auto;
  text-align: center;
}

.successImg img {
  width: 100%;
}

.width60 {
  width: 65% !important;
}

.width40 {
  width: 40%;
}