#sidebar {
  background-color: #fff;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 30px 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  width: 120px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
}

.active-menu_link {
  /* justify-content: center; */
  background: #404040;
  color: #fff;
}

.active-menu_link a {
  color: #fff !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: #000;
  font-size: 13px;
  margin-left: 10px;
  padding-top: 2px;
  /* font-weight: 700; */
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 16px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

.icon__img {
  width: 16px;
}

.icon__img img {
  width: 100%;
}

.nav_icons {
  display: none !important;
}
@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }

  .nav_icons {
    display: flex !important;
  }
}
